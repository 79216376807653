<template>
  <div>
    <v-alert type="info" prominent border="left">
      <v-row align="center">
        <v-col class="grow">
          {{ countCreatable }} Portfolios können automatisch für
          {{ term ? term.shortText : "" }} erzeugt werden.
          {{ countCreated }} wurden bereits erzeugt.
        </v-col>
        <v-col class="shrink">
          <v-btn
            :disabled="countCreatable == 0"
            @click="createAll"
            :loading="creating"
            >erzeugen</v-btn
          >
        </v-col>
      </v-row>
    </v-alert>
    <v-toolbar flat class="mb-4">
      <PersonInput
        clearable
        dense
        hide-details
        label="für"
        class="mr-2"
        v-model="filter.student"
        group="student"
      />

      <CourseInput
        clearable
        dense
        hide-details
        label="von"
        class="mr-2"
        v-model="filter.course"
        :startDate="term ? term.startDate : null"
        :endDate="term ? term.endDate : null"
      />
      <v-autocomplete
        hide-details
        clearable
        dense
        class="mr-2"
        v-model="filter.type"
        :items="types"
        item-text="description"
        item-value="id"
        return-object
        label="Typ"
      ></v-autocomplete>
      <v-spacer />
      <v-switch inset v-model="onlyNew" label="nur Neue"></v-switch>
    </v-toolbar>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="itemsFiltered"
        :items-per-page="15"
        :loading="loading"
        item-key="index"
      >
        <template v-slot:item.creation="{ item }">
          <DateValue :value="item.creationDate" />, {{ item.creationTime }}
        </template>
        <template v-slot:item.student="{ item }">
          <PersonItem :value="item.student" />
        </template>
        <template v-slot:item.course="{ item }">
          <Course :value="item.course" />
        </template>
        <template v-slot:item.id="{ item }">
          <v-btn
            icon
            v-if="item.id"
            :to="{ name: 'PortfolioDetail', params: { id: item.id } }"
            ><v-icon>mdi-text-box</v-icon></v-btn
          >
          <v-btn color="success" icon v-else @click="create(item)"
            ><v-icon>mdi-creation</v-icon></v-btn
          >
        </template>
      </v-data-table>
    </v-card>
    <router-view />
  </div>
</template>

<script>
import { searchPerson } from "common/utils/people.js";
import DateValue from "common/components/DateValue.vue";
import Course from "@/components/Course.vue";
import CourseInput from "common/components/CourseInput.vue";
import PersonItem from "@/components/PersonItem.vue";
import PersonInput from "common/components/PersonInput.vue";
export default {
  name: "Courses",
  props: ["search", "term"],
  components: { Course, CourseInput, DateValue, PersonItem, PersonInput },
  data: () => ({
    creating: false,
    onlyNew: false,
    filter: {
      student: null,
      course: null,
      type: null,
    },
    headers: [
      { text: "Typ", value: "type.description", sortable: false },
      { text: "für", value: "student", sortable: false },
      { text: "in", value: "course", sortable: false },
      { text: "", value: "id", sortable: false },
    ],
    items: [],
    types: [],
    loading: false,
  }),
  computed: {
    countCreatable() {
      return this.itemsFiltered.filter((item) => item.id == 0).length;
    },
    countCreated() {
      return this.itemsFiltered.filter((item) => item.id > 0).length;
    },
    itemsFiltered() {
      return this.itemsWithIndex.filter(
        (item) =>
          (!this.onlyNew || !item.id) &&
          (!this.filter.type || this.filter.type.id === item.type.id) &&
          (!this.filter.student ||
            this.filter.student.id === item.student.id) &&
          (!this.filter.course || this.filter.course.id === item.course.id) &&
          (!this.search ||
            item.type.description
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            searchPerson(item.student, this.search))
      );
    },
    itemsWithIndex() {
      return this.items.map((items, index) => ({
        ...items,
        index: index,
      }));
    },
  },
  watch: {
    term() {
      this.getData();
    },
  },
  methods: {
    async create(item) {
      const data = {
        course: item.course.id,
        student: item.student.id,
        type: item.type.id,
      };
      await this.apiPost({ resource: "portfolio/portfolio", data: data });
      this.getData();
    },
    async createAll() {
      this.creating = true;
      await Promise.all(
        this.itemsFiltered.map(async (item) => {
          if (item.id == 0) {
            const data = {
              course: item.course.id,
              student: item.student.id,
              type: item.type.id,
            };
            await this.apiPost({ resource: "portfolio/portfolio", data: data });
          }
        })
      );
      this.creating = false;
      this.getData();
    },
    async getData() {
      this.loading = true;
      this.types = (
        await this.apiList({ resource: "portfolio/type", query: "archived" })
      ).filter((el) => el.autogenerate);
      if (this.term) {
        this.items = await this.apiList({
          resource: "portfolio/creation",
          query: `startDate=${this.term.startDate}&&endDate=${this.term.endDate}`,
        });
      }
      this.loading = false;
    },
    showPortfolio(id) {
      this.$router.push({
        name: "PortfolioDetail",
        params: { id: id },
      });
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === "PortfolioCourses") {
      this.getData();
    }
    next();
  },
  created() {
    this.getData();
  },
};
</script>
